<template>
  <generic-modal :opened="opened" @close="$emit('close')">
    <template v-slot:header>
      <div class="modal-box__title">Отклонение документа</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <svg-icon name="cross" :size="24" />
      </button>
    </template>

    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <text-area
                    label="Комментарий"
                    v-model="form.comment"
                    @change="vuelidate.form.comment.$touch"
                    :has-error="vuelidate.form.comment.$error"
                    :vuelidate-errors="vuelidate.form.comment.$errors"
                />
              </div>
              <div class="col col--full">
                <file-attach
                    label="Файл (необязательно)"
                    v-model="form.file"
                    @update:model-value="vuelidate.form.file.$touch"
                    :has-error="vuelidate.form.file.$error"
                    :error-text="vuelidate.form.file.$errors[0]?.$message"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button
              button-type="submit"
              label="Отклонить"
              @click="submit"
          />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import TextArea from '@/components/TextArea.vue';
import FileAttach from '@/components/FileAttach.vue';
import CustomButton from '@/components/CustomButton.vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';

export default {
  name: 'RejectDocumentModal',
  components: {
    GenericModal,
    SvgIcon,
    TextArea,
    FileAttach,
    CustomButton,
  },
  props: {
    opened: Boolean,
    documentId: [String, Number],
  },
  emits: ['close', 'submit'],
  setup() {
    return { vuelidate: useVuelidate() };
  },
  data() {
    return {
      form: {
        comment: '',
        file: null,
      },
      vuelidateExternalResults: { form: {} },
    };
  },
  validations() {
    return {
      form: {
        comment: { required },
        file: {}, // необязательный файл
      },
    };
  },
  methods: {
    submit() {
      this.vuelidateExternalResults.form = {};
      this.vuelidate.form.$touch();
      if (this.vuelidate.form.$invalid) {
        return;
      }

      this.$emit('submit', this.getFormData());
      this.$emit('close');
      this.resetForm();
    },
    getFormData() {
      const formData = new FormData();
      formData.append('documentId', this.documentId);
      formData.append('comment', this.form.comment);
      if (this.form.file) {
        formData.append('file', this.form.file);
      }
      return formData;
    },
    resetForm() {
      this.form = {
        comment: '',
        file: null,
      };
      this.vuelidate.form.$reset();
    },
  },
};
</script>
