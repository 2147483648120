<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
                <tr>
                  <th v-for="column in $options.columns" :key="column">{{ column }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="documents && !documents.length">
                  <td :colspan="$options.columns.length" class="align-center">Документы не найдены</td>
                </tr>
                <template v-else>
                  <tr v-for="document in documents" :key="document.id">
                    <td>{{ document.type }}</td>
                    <td>{{ document.number }}</td>
                    <td>
                      <a
                        v-if="document.file && document.file.path"
                        :href="document.file.path"
                        :download="document.file.name ? document.file.name : ''"
                      >
                        {{ document.file.name }}
                      </a>
                    </td>
                    <td>{{ this.$filters.formatDate(document.file?.createdAt) }}</td>
                    <td>{{ document.comment }}</td>
                    <td class="align-center">
                      <button
                          v-if="document.messageData"
                          class="link link--gray"
                          type="button"
                          @click="acceptDocument(document)"
                      >
                        <svg-icon name="add" :size="16" />
                      </button>
                    </td>
                    <td class="align-center">
                      <button
                          v-if="document.messageData"
                          class="link link--gray"
                          type="button"
                          @click="rejectDocument(document)"
                      >
                        <svg-icon name="cross" :size="16" />
                      </button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <BankDetailsModal
      :opened="modals.bankDetailsModal.isOpen"
      :document-id="modals.bankDetailsModal.documentId"
      @close="modals.bankDetailsModal.isOpen = false"
      @submit="handleBankDetailsSubmit"
  />
  <RejectDocumentModal
      :opened="modals.rejectDocumentModal.isOpen"
      :document-id="modals.rejectDocumentModal.documentId"
      @close="modals.rejectDocumentModal.isOpen = false"
      @submit="rejectDocumentModal"
  />
</template>

<script>
import { Expertises } from '@/repositories';
import SvgIcon from "@/components/SvgIcon.vue";
import BankDetailsModal from "@/components/modals/BankDetailsModal.vue";
import RejectDocumentModal from "@/components/modals/RejectDocumentModal.vue";

export default {
  name: 'ExpertiseDocumentsForExpert',
  components: {SvgIcon, RejectDocumentModal, BankDetailsModal},
  columns: ['Тип документа', 'Номер', 'Название файла', 'Дата загрузки', 'Примечание', 'принять', 'отказать'],
  created() {
    this.getDocuments();
  },
  watch: {
    id() {
      this.getDocuments();
    },
  },
  data() {
    return {
      documents: null,
      modals: {
        bankDetailsModal: {
          isOpen: false,
          documentId: null,
        },
        rejectDocumentModal: {
          isOpen: false,
          documentId: null,
        }
      },
    };
  },
  methods: {
    getDocuments() {
      Expertises.getDocumentsForExpert(this.id).then((documents) => (this.documents = documents));
    },
    acceptDocument(document) {
      if (document.isAccountDetails) {
        this.modals.bankDetailsModal.isOpen = true;
        this.modals.bankDetailsModal.documentId = document.id;
        return;
      }

      Expertises.formatPdf(this.id, document.id);
    },
    rejectDocument(document) {
      this.modals.rejectDocumentModal.isOpen = true;
      this.modals.rejectDocumentModal.documentId = document.id;
    },
    async handleBankDetailsSubmit(details) {
      try {
        await Expertises.formatPdf(this.id, details.documentId, details);
        this.getDocuments();
      } catch (error) {
        console.error(error);
        alert('Ошибка при сохранении реквизитов или создании PDF.');
      }
    },

    async rejectDocumentModal(formData) {
      await Expertises.rejectDocument(this.id, formData);
      this.getDocuments();
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
};
</script>
