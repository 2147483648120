<template>
  <generic-modal :opened="opened" @close="$emit('close')">
    <template  v-slot:header>
      <div class="modal-box__title">Реквизиты банка</div>
      <button @click="$emit('close')" class="link link--gray modal-close" type="button">
        <svg-icon name="cross" :size="24" />
      </button>
    </template>

    <template v-slot:body>
      <div class="modal-box__block">
        <div class="block">
          <div class="block__main">
            <div class="row row--aife">
              <div class="col col--full">
                <input-text
                    label="Лицевой счёт"
                    v-model="form.accountNumber"
                    :has-error="v$.accountNumber.$error"
                    :vuelidate-errors="v$.accountNumber.$errors"
                    @change="v$.accountNumber.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Тип банковской карты"
                    v-model="form.cardType"
                    :has-error="v$.cardType.$error"
                    :vuelidate-errors="v$.cardType.$errors"
                    @change="v$.cardType.$touch"
                />
              </div>
              <div class="col col--full">
                <text-area
                    label="Реквизиты банка"
                    v-model="form.bankDetails"
                    :has-error="v$.bankDetails.$error"
                    :vuelidate-errors="v$.bankDetails.$errors"
                    @change="v$.bankDetails.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Наименование банка получателя"
                    v-model="form.bankName"
                    :has-error="v$.bankName.$error"
                    :vuelidate-errors="v$.bankName.$errors"
                    @change="v$.bankName.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Кор. счёт"
                    v-model="form.correspondentAccount"
                    :has-error="v$.correspondentAccount.$error"
                    :vuelidate-errors="v$.correspondentAccount.$errors"
                    @change="v$.correspondentAccount.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="Расч. счёт"
                    v-model="form.settlementAccount"
                    :has-error="v$.settlementAccount.$error"
                    :vuelidate-errors="v$.settlementAccount.$errors"
                    @change="v$.settlementAccount.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="БИК"
                    v-model="form.bik"
                    :has-error="v$.bik.$error"
                    :vuelidate-errors="v$.bik.$errors"
                    @change="v$.bik.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="ИНН банка"
                    v-model="form.inn"
                    :has-error="v$.inn.$error"
                    :vuelidate-errors="v$.inn.$errors"
                    @change="v$.inn.$touch"
                />
              </div>
              <div class="col col--full">
                <input-text
                    label="КПП банка"
                    v-model="form.kpp"
                    :has-error="v$.kpp.$error"
                    :vuelidate-errors="v$.kpp.$errors"
                    @change="v$.kpp.$touch"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template  v-slot:footer>
      <div class="line line--jcfe">
        <div class="line__item">
          <custom-button
              label="Сохранить"
              button-type="submit"
              @click="submit"
          />
        </div>
      </div>
    </template>
  </generic-modal>
</template>

<script>
import { reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@/utils/validators';
import GenericModal from '@/components/modals/GenericModal.vue';
import SvgIcon from '@/components/SvgIcon.vue';
import InputText from '@/components/InputText.vue';
import TextArea from '@/components/TextArea.vue';
import CustomButton from '@/components/CustomButton.vue';

export default {
  name: 'BankDetailsModal',
  components: {
    GenericModal,
    SvgIcon,
    InputText,
    TextArea,
    CustomButton,
  },
  props: {
    opened: Boolean,
    documentId: [String, Number],
  },
  emits: ['close', 'submit'],
  setup(props, { emit }) {
    const form = reactive({
      accountNumber: '',
      cardType: '',
      bankDetails: '',
      bankName: '',
      correspondentAccount: '',
      settlementAccount: '',
      bik: '',
      inn: '',
      kpp: '',
    });

    const rules = {
      accountNumber: { required },
      cardType: { required },
      bankDetails: { required },
      bankName: { required },
      correspondentAccount: { required },
      settlementAccount: { required },
      bik: { required },
      inn: { required },
      kpp: { required },
    };

    const v$ = useVuelidate(rules, form);

    const submit = () => {
      v$.value.$touch();
      if (v$.value.$invalid) return;

      emit('submit', {
        documentId: props.documentId,
        ...form,
      });

      emit('close');
    };

    return {
      form,
      v$,
      submit,
    };
  },
};
</script>
