<template>
  <div class="main__body">
    <div class="main__block">
      <div class="block">
        <div class="block__main">
          <div class="tbl-wrap">
            <table class="tbl">
              <thead>
              <tr>
                <th v-for="column in $options.columns" :key="column">{{ column }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="documents && !documents.length">
                <td :colspan="$options.columns.length" class="align-center">
                  Документы не найдены
                </td>
              </tr>
              <template v-else>
                <tr v-for="document in documents" :key="document.id">
                  <td>{{ document.type }}</td>
                  <td>
                    <file-link
                        v-if="document.file"
                        :path="document.file.path"
                        :download-as="this.$filters.removeExtension(document.file.name, 'sig')"
                    >
                      {{ document.file.name }}
                    </file-link>
                  </td>
                  <td>{{ this.$filters.formatDate(document.file?.createdAt) }}</td>
                  <td>{{ document.comment }}</td>
                  <td class="align-center">
                    <SwitchInput
                        :disabled="!document.canBeSigned"
                        type="checkbox"
                        size="s"
                        :value="document.id"
                        v-model="idsOfDocumentsToSign"
                    />
                  </td>
                  <td class="align-center">
                    <button
                        class="link link--gray"
                        type="button"
                        @click="removeDocument(document.id)"
                    >
                      <svg-icon name="delete" :size="16" />
                    </button>
                  </td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main__footer main__footer--sticky">
    <div class="line line--jcfe">
      <div class="line__item">
        <custom-button
            label="Подписать"
            :disabled="modals.signDocuments.data.documents?.length === 0"
            @click="openSignDocumentsModal"
        />
      </div>
      <div class="line__item">
        <custom-button :type="2" label="Добавить документ" @click="modalIsOpen = true" />
      </div>
    </div>
  </div>
  <create-expertise-document
      :expertise-id="id"
      :opened="modalIsOpen"
      @close="modalIsOpen = false"
      @created="getExpertDocuments"
  />
  <SigningModal
      v-if="modals.signDocuments.isOpen"
      :hashes="modals.signDocuments.data.hashes.data"
      :loading="modals.signDocuments.isLoading"
      @close="closeSignDocumentsModal"
      @signed:hashes="storeDocumentsSignatures"
      @changed:certificate="handleCertificateChanged"
      size="lg"
  >
    <template #body>
      <div class="row row-aife">
        <div class="col col--full">
          <table class="tbl">
            <thead>
            <tr>
              <th>Тип документа</th>
              <th>Название файла</th>
              <th>Дата загрузки</th>
              <th>Примечание</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="!modals.signDocuments.data.certificate">
              <td colspan="4" class="align-center">Сначала выберите сертификат для подписи</td>
            </tr>
            <tr v-else-if="modals.signDocuments.data.hashes.isLoading">
              <td colspan="4"><div class="loader"><SvgClr name="loader" :size="24" /></div></td>
            </tr>
            <template v-else-if="!modals.signDocuments.data.hashes.isLoading">
              <tr v-for="document in documentsToBeSigned" :key="document.id">
                <td>{{ document.type }}</td>
                <td>{{ document.file?.name }}</td>
                <td>{{ this.$filters.formatDate(document.file?.createdAt) }}</td>
                <td>{{ document.comment }}</td>
              </tr>
              <tr>
                <td
                    v-if="documentsToBeSigned.length < idsOfDocumentsToSign.length"
                    colspan="4"
                    class="align-center error-text"
                >
                  Для некоторых документов не удалось найти файлы, по этому они не будут подписаны.
                </td>
              </tr>
            </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </SigningModal>
</template>

<script>
import SvgIcon from '@/components/SvgIcon';
import CreateExpertiseDocument from '@/components/modals/CreateExpertiseDocument';
import CustomButton from '@/components/CustomButton';
import { Expertises } from '@/repositories';
import FileLink from '@/components/FileLink';
import SigningModal from '@/components/modals/SigningModal.vue';
import SwitchInput from "@/components/SwitchInput.vue";
import SvgClr from "@/components/SvgClr.vue";
import { formatDate } from "@/utils/filters/formatDate";

export default {
  name: 'ExpertiseExpertDocuments',
  components: {SvgClr, SwitchInput, SigningModal, SvgIcon, CreateExpertiseDocument, CustomButton, FileLink },
  columns: ['Тип документа', 'Название файла', 'Дата загрузки', 'Примечание', '', ''],
  created() {
    this.getExpertDocuments();
  },
  watch: {
    id() {
      this.getExpertDocuments();
    },
  },
  data() {
    return {
      documents: null,
      idsOfDocumentsToSign: [],
      hashToDocumentId: {},
      modalIsOpen: false,
      signingInProgress: false,
      modals: {
        createDocument: {
          isOpen: false,
        },
        signDocuments: {
          isOpen: false,
          isLoading: false,
          data: {
            hashes: {
              isLoading: false,
              data: [],
            },
            certificate: null,
          },
        },
      },
    };
  },
  computed: {
    documentsById() {
      return this.documents?.reduce((result, document) => {
        result[document.id] = document;
        return result;
      }, {});
    },
    documentsToBeSigned() {
      return this.idsOfDocumentsToSign.filter(this.hasHash).map((id) => this.documentsById[id]);
    },
  },
  methods: {
    getExpertDocuments() {
      Expertises.getExpertDocuments(this.id).then((documents) => (this.documents = documents.filter((document) => document.visibleUser)));
    },
    removeDocument(documentId) {
      if (!window.confirm('Документ будет безвозвратно удалено, Вы желаете продолжить?')) {
        return;
      }
      Expertises.removeDocument(this.id, documentId).then(() => {
        this.documents = this.documents.filter((doc) => doc.id !== documentId);
      });
    },
    openSignDocumentsModal() {
      this.modals.signDocuments.isOpen = true;
    },
    closeSignDocumentsModal() {
      this.modals.signDocuments.isOpen = false;
      this.unsetHashes();
      this.modals.signDocuments.data.certificate = null;
    },
    /** @param {Certificate|null} certificate */
    handleCertificateChanged(certificate) {
      this.modals.signDocuments.data.certificate = certificate;
      if (certificate) {
        this.loadHashes({
          number: certificate.thumbprint,
          subject: certificate.name,
          dateFrom: formatDate(certificate.validFrom),
          dateUntil: formatDate(certificate.validTo),
        });
        return;
      }

      this.unsetHashes();
    },
    async loadHashes(signer) {
      this.modals.signDocuments.data.hashes.isLoading = true;
      Expertises.getDocumentsHashes(this.id, this.idsOfDocumentsToSign, signer)
          .then((results) => {
            this.modals.signDocuments.data.hashes.data = results.map((result) => result.hash);
            this.hashToDocumentId = results.reduce((dictionary, result) => {
              dictionary[result.hash] = result.document_id;
              return dictionary;
            }, {});
          })
          .catch(() => window.alert('Не удалось получить данные о документах. Попробуйте, пожалуйста, позже!'))
          .finally(() => this.modals.signDocuments.data.hashes.isLoading = false);
    },
    unsetHashes() {
      this.modals.signDocuments.data.hashes = [];
      this.modals.signDocuments.data.hashes.isLoading = false;
    },
    storeDocumentsSignatures(signResults) {
      const payload = signResults
          .filter((result) => this.hashToDocumentId[result.hash])
          .map((result) => {
            return {
              document_id: this.hashToDocumentId[result.hash],
              signature: result.signature,
              hash: result.hash,
            };
          });

      this.modals.signDocuments.isLoading = true;
      Expertises.signDocuments(this.id, payload)
          .catch(() => window.alert('Не удалось подписать документы. Попробуйте, пожалуйста, позже!'))
          // Перезагружаем список документов для отображения результатов
          .then(() => this.getExpertDocuments())
          .then(() => this.closeSignDocumentsModal())
          .finally(() => (this.modals.signDocuments.isLoading = false))
          .catch(() => window.alert('Что-то пошло не так. Пожалуйста, перезагрузите страницу.'));
    },
    hasHash(document) {
      const id = document instanceof Object ? document.id : document;
      return Object.values(this.hashToDocumentId).includes(id);
    }
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    expertIsChairman: Boolean,
  },
};
</script>